import _ from 'lodash';
import React, { useState, useEffect } from "react";
import { Space, Button, Modal, Avatar, Card, Row, Col, Switch, Divider, Badge, message, Typography } from "antd";
// import init from 'monday-sdk-js/src/client';
import { Formik, Field, FieldArray } from 'formik';
import * as yup from 'yup';
import { CloseCircleFilled } from '@ant-design/icons';
import firebase from "firebase/compat/app";
import teamFunctions from "../../firebaseService/teamFunctions";
import toastHandler from "../../firebaseService/toastHandler";
import Meta from "antd/lib/card/Meta";
import SelectComponent from '../Common/FormikComponents/SelectComponent';
import Selectworkspace from '../Common/FormikComponents/Selectworkspace';
import Selectboard from '../Common/FormikComponents/Selectboard';
import SelectAssigneeColumn from '../Common/FormikComponents/SelectAssigneeColumn';
import SelectNumberColumn from '../Common/FormikComponents/SelectNumberColumn';
import InputComponent from '../Common/FormikComponents/InputComponent';
import SelectStatusColumn from '../Common/FormikComponents/SelectStatusColumn';
import SelectDoneStatusLabel from '../Common/FormikComponents/SelectDoneStatusLabel';

import "antd/dist/antd.css";
import mondaySdk from "monday-sdk-js";
import MondayConfig from '../../configs/MondayConfig';
import team from '../../constants/team';
import sentryUtils from '../../utils/sentryUtils';

import { analytics} from '../../firebase';
// import { getAnalytics, logEvent } from "firebase/analytics";
// const analytics = getAnalytics();
import { logEvent } from 'firebase/analytics';

const { Title, Paragraph } = Typography;

export const emptySchema = yup.object().shape({});

export const validateSchema1 = yup.object().shape({
    workSpaceName: yup.string().required('Please select workspace'),
    projectName: yup.string().required('Please select project'),
});

export const validateSchema2 = yup.object().shape({
    teamName: yup.string().required('Team name is required'),
    assigneeColName: yup.string().required('Please select asssignee column'),
});

export const validateSchema3 = yup.object().shape({
    pmName: yup.string().required('Please select project manager'),
    leadName: yup.string().required('Please select Lead/Approver'),
    teamMember: yup.array()
                .min(1, "Please select atleast one team member")
                .of(
                    yup.object().shape({
                        key: yup.string().required('Key is required'),
                        value: yup.string().required('Value is required'),
                        // key: yup.string().required()
                    })
        )
});

function TeamInfo(props) {
    const { show, setShow, teamInfo } = props;
    const monday = mondaySdk();
    monday.setApiVersion(MondayConfig.MONDAY_API_VERSION)
    const user = firebase.auth().currentUser;
    const [userData, setUserData] = useState([]);

    const id = _.get(teamInfo, 'docId') || 0;
    const projectName = _.get(teamInfo, 'project.value');

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [selectedWorkSpace, setSelectedWorkSpace] = useState(id ? _.get(teamInfo, 'workSpace') : {});
    const [selectedProject, setSelectedProject] = useState(id ? _.get(teamInfo, 'project') : {});
    const [selectedSubitemProject, setSelectedSubitemProject] = useState(id ? _.get(teamInfo, 'subitemProject') : {});
    
    const [selectedPM, setSelectedPM] = useState(id ? _.get(teamInfo, 'projectManager') : {});
    const [selectedAssigneeColumn, setSelectedAssigneeColumn] = useState(id ? _.get(teamInfo, 'assigneeColumn') : {});
    const [selectedHoursSpentColumn, setSelectedHoursSpentColumn] = useState(id ? _.get(teamInfo, 'hoursSpentColumn') : {});
    const [selectedHoursEstimatedColumn, setSelectedHoursEstimatedColumn] = useState(id ? _.get(teamInfo, 'hoursEstimatedColumn') : {});
    
    const [selectedSubitemAssigneeColumn, setSelectedSubitemAssigneeColumn] = useState(id ? _.get(teamInfo, 'subitemAssigneeColName') : {});
    const [selectedSubitemHoursSpentColumn, setSelectedSubitemHoursSpentColumn] = useState(id ? _.get(teamInfo, 'subitemHoursSpentColumn') : {});
    const [selectedSubitemHoursEstimatedColumn, setSelectedSubitemHoursEstimatedColumn] = useState(id ? _.get(teamInfo, 'subitemHoursEstimatedColumn') : {});
    const [subitemEnabled, setSubitemEnabled] = useState(id ? _.get(teamInfo, 'subitemEnabled') : false);
    const [selectedLead, setSelectedLead] = useState(id ? _.get(teamInfo, 'lead') : {});
    const [selectedStatusColumn, setSelectedStatusColumn] = useState(id ? _.get(teamInfo, 'taskStatusColumn') : {});
    const [selectedStatusLabelColumn, setSelectedStatusLabelColumn] = useState(id ? _.get(teamInfo, 'taskStatusLabel') : {});


    const [selectedTeamMember, setSelectedTeamMember] = useState(null);
    var teamMate = teamInfo ? teamInfo.teamMates?.map(function (value) {
        return { key: value.userId, value: value.userName, isDeleted: value.isDeleted }
    }) : [];
    const [teamMember, setTeamMember] = useState(teamMate);
    const [addTeamMates, setAddTeamMates] = useState([]);
    const [newMembers, setNewMembers] = useState([])
    const [deletedTeamMatesArray, setDeletedTeamMatesArray] = useState([]);
    const [formOne, setFormOne] = useState(true);
    const [formTwo, setFormTwo] = useState(false);
    const [formThree, setFormThree] = useState(false);
    const [formStatus, setFormStatus] = useState(false);

    const [title, setTitle] = useState(id ? team.TITLE1U : team.TITLE1)
    const [description, setDescription] = useState(team.DESCRIPTION1)
    const [isPrev, setIsPrev] = useState(false)
    

    const [messageApi, contextHolder] = message.useMessage();
    message.config({
        duration: 5,
        maxCount: 0,
    });
    const warning = (message) => {
        messageApi.warning(message);
    };

    const handleAddTeamMember = () => {
        if (id) {
            let newlyAddedMembers = newMembers.map(function (e) { return { ...e, isDeleted: e.isDeleted ? e.isDeleted : false, userId: e.key, userName: e.value } })
            newlyAddedMembers.forEach(member => {
                setDeletedTeamMatesArray(prevDeleted => 
                    prevDeleted.filter(deletedMember => deletedMember.userId !== member.userId)
                );
            });
            setAddTeamMates(prevAdd => [...prevAdd, ...newlyAddedMembers]);
            // setAddTeamMates([...newlyAddedMembers])
        }
        else {
            setTeamMember(newMembers.map(function (e) { return { ...e, isDeleted: false } }))
        }
    }

    useEffect(() => {
        if (newMembers.length) {
            handleAddTeamMember()
        }
    }, [newMembers])

    const handleDeleteTeamMember = (name, value) => {
        const { key } = value
        //check if this key is present in addTeamMates as well as teamMember when in edit
        //if present delete it from that object and create array of deleted array
        if (props.teamInfo) {
            // const newTeam = teamMember.filter((teamMember) => teamMember.key !== key);
            // setTeamMember(newTeam);
            const newTeamMate = addTeamMates.filter((addTeamMates) => addTeamMates.userId !== key);
            setAddTeamMates(newTeamMate);

            const deletedTeamMates = {
                userId: key,
                userName: name
            };
            setDeletedTeamMatesArray([...deletedTeamMatesArray, deletedTeamMates])

            setSelectedTeamMember(null)
        }
    }

    const onPrevBtnClick = () => {
        setIsPrev(true)
        if(formTwo){
            setFormTwo(false)
            setFormOne(true)
            setTitle(id ? team.TITLE1U : team.TITLE1)
            setDescription(team.DESCRIPTION1)
        } else if(formStatus){
            setFormStatus(false)
            setFormTwo(true)
            setTitle(id ? team.TITLE2U : team.TITLE2)
            setDescription(team.DESCRIPTION2)
        }else if(formThree){
            setFormThree(false)
            setFormStatus(true)
            setTitle(id? team.TITLE_STATUS_2 :team.TITLE_STATUS_1)
            setDescription(team.DESCRIPTION_STATUS)
        }
    }
   

    const handleSubmit = (values) => {
       
        setIsPrev(false)
        // console.log("submit values", values)
        // console.log('formOne', formOne)
        if(formOne){
            setFormOne(false)
            setFormTwo(true)
            setTitle(id ? team.TITLE2U : team.TITLE2)
            setDescription(team.DESCRIPTION2)
        }
        if(formTwo){
            setFormTwo(false)
            setFormStatus(true)
            setTitle(id? team.TITLE_STATUS_2 :team.TITLE_STATUS_1)
            setDescription(team.DESCRIPTION_STATUS)
            
        }
        if(formStatus){
            setFormStatus(false)
            setFormThree(true)
            setTitle(id ? team.TITLE3U : team.TITLE3)
            setDescription(team.DESCRIPTION3)
        }
        if(formThree){
            setIsSubmitted(true)
            if (id) {
                const updateTeam = {
                    project: { key: parseInt(selectedProject.key) },
                    teamName: values.teamName,
                    projectManager: teamInfo.projectManager?.userName != values.pmName ? {
                        userId: values.pmId,
                        userName: values.pmName,
                    } : null,
                    lead: teamInfo.lead?.userName != values.leadName ? {
                        userId: values.leadId,
                        userName: values.leadName,
                    } : null,
                    addTeamMates: addTeamMates,
                    deleteTeamMates: deletedTeamMatesArray,
                    assigneeColumn: selectedAssigneeColumn,
                    hoursSpentColumn: selectedHoursSpentColumn,
                    hoursEstimatedColumn: selectedHoursEstimatedColumn,
                    subitemEnabled: subitemEnabled,
                    subitemAssigneeColumn: subitemEnabled ? selectedSubitemAssigneeColumn : null,
                    subitemHoursSpentColumn: subitemEnabled ? selectedSubitemHoursSpentColumn : null,
                    subitemHoursEstimatedColumn: subitemEnabled ? selectedSubitemHoursEstimatedColumn : null,
                    subitemProject: subitemEnabled ? selectedSubitemProject : null,
                    taskStatusColumn: selectedStatusColumn,
                    taskStatusLabel: selectedStatusLabelColumn,
                    // statusColumnName: values.statusColumnName,
                    // statusColumnId: values.statusColumnId,
                    // statusColumnLabelName: values.statusColumnLabelName,
                };
                
                // firebase.analytics().logEvent('button_click', { name: 'example_button' });
                // logEvent(analytics, 'button_click', { button_name: 'example_button' });
                // logEvent(analytics, 'updateTeam')
                // analytics.logEvent('create_team',"");
                // logEvent(analytics, 'update Team', {
                //     content_type: 'TeamForm',
                //     content_id: selectedProject.label 
                //   });
                teamFunctions.updateTeam(updateTeam,values.teamName);
                // logEvent(analytics, 'update_Team', { "account_id": localStorage.getItem("accountId") });
            } else {
                const createTeam = {
                    ...values,
                    teamMates: teamMember,
                    workSpaceName: selectedWorkSpace,
                    projectBoardId: values.projectBoardId + "",
                    leadId: values.leadId + "",
                    pmId: values.pmId + "",
                    prjoectName: selectedProject.label,
                    assigneeColumn: selectedAssigneeColumn,
                    hoursSpentColumn: selectedHoursSpentColumn,
                    hoursEstimatedColumn: selectedHoursEstimatedColumn,
                    subitemEnabled: subitemEnabled,
                    subitemAssigneeColumn: subitemEnabled ? selectedSubitemAssigneeColumn : null,
                    subitemHoursSpentColumn: subitemEnabled ? selectedSubitemHoursSpentColumn : null,
                    subitemHoursEstimatedColumn: subitemEnabled ? selectedSubitemHoursEstimatedColumn : null,
                    subitemProject: subitemEnabled ? selectedSubitemProject : null,
                    taskStatusColumn: selectedStatusColumn,
                    taskStatusLabel: selectedStatusLabelColumn,
                };

                // logEvent(analytics, 'create_team');
                // logEvent(analytics, "page_view")
                // analytics.logEvent('create_team',"");
                console.log("team event created",values.teamName)
                delete createTeam['teamMember'];
                teamFunctions.createTeam(createTeam,values.teamName);
                monday.execute('valueCreatedForUser')
                // logEvent(analytics, 'create_Team', { "account_id" : localStorage.getItem("accountId") });
            }
            // if(analytics)
            // {
            //     logEvent(analytics, 'button_click', { button_name: 'team_update or Create' });
            //     console.log("team event  updated/created")
            // }
            props.onCancel();
        }
    }

    const fetchUser = async () => {
        let query = `query {
            boards (ids: ${selectedProject.key}) {
              name,
              subscribers{
                name
                id
              }
            }
          }`;
        const tokendata = await monday.get('sessionToken');
        const response = await monday.api(
            query,
            {
                options: { token: tokendata.data },
            }
        ).catch((err) => {
            console.log(err.message);
            toastHandler.error(err.message);
            sentryUtils.captureMondayException('fetchUser', err, 'TeamForm');
        });
        let users = _.get(response, 'data.boards[0].subscribers') || [];
        let userData = [];
        users.forEach((item, index) => {
            userData.push({
                key: String(item.id),
                label: item.name,
                value: item.name,

            });
        });
        setUserData(userData);
        if (
            response.data.boards.length < 1 ||
            response.data.boards[0] === undefined
        ) {
            warning(`You don't have access to ${projectName} (monday board)`);
            setIsSubmitted(true)
        }
        else {
            if (userData.length <= 1) {
                warning('Please invite more members on your project (i.e. monday board).')
            }
            if (id) {
                if (teamMember) {
                    let deletedTeamMates = [...deletedTeamMatesArray]//deletedTeamMatesArray.length>0?[...deletedTeamMatesArray]:deletedTeamMatesArray
                    let updatedTeamMember = [];
                    //compare team members in database with realtime members 
                    teamMember.forEach((member) => {
                        const matchingUser = userData.find(user => user.key === member.key);
                        const isPM = selectedPM.userId === member.key;
                        if (!matchingUser) {
                            if (!member.isDeleted) {
                                deletedTeamMates.push({
                                    userId: member.key,
                                    userName: member.value
                                });
                                // let found_elem = _.find(userData, ['key', member.key]);
                                // if (!found_elem) {
                                //     warning('Some team members are removed from this project ( i.e. monday board).')
                                // }
                            }
                        }
                        else if(isPM){
                            if (!member.isDeleted) {
                                deletedTeamMates.push({
                                    userId: member.key,
                                    userName: member.value
                                });
                                member.isDeleted = true
                                updatedTeamMember.push(member);
                            }
                        }
                        else {
                            updatedTeamMember.push(member);
                        }
                    })
                    setTeamMember(updatedTeamMember);
                    setDeletedTeamMatesArray(deletedTeamMates);
                }
            

                if (selectedPM?.userId === selectedLead?.userId) {
                    let isFound = _.find(userData, ['key', selectedPM?.userId]);
                    if (!isFound) {
                        warning('Project manager & Lead is removed from this project ( i.e. monday board).')
                    }
                } else {
                    //check if project manage is removed from board/project
                    let isPMFound = _.find(userData, ['key', selectedPM?.userId]);
                    //check lead is remove from project
                    let isLeadFound = _.find(userData, ['key', selectedLead?.userId]);
                    if (!isPMFound && !isLeadFound) {
                        warning('Project manager & Lead is removed from this project ( i.e. monday board).')
                    } else {
                        if (!isPMFound) {
                            warning('Project manager is removed from this project ( i.e. monday board).')
                        }

                        if (!isLeadFound) {
                            warning('Lead is removed from this project ( i.e. monday board).')
                        }
                    }
                }
            }
        }
    }

    const fetchSubitemBoard = async () => {
        let query = ` query {
            boards (ids: ${selectedProject.key}) {
                columns(types: [subtasks]){
                    settings_str
                }
            }
        }`;
        const tokendata = await monday.get('sessionToken');
        const response = await monday.api(
            query,
            {
                options: { token: tokendata.data },
            }
        ).catch((err) => {
            console.log(err.message);
            toastHandler.error(err.message);
            sentryUtils.captureMondayException('fetchSubitemBoard', err, 'TeamForm');
        });
        
        // Extract boardIds
        const boardIds = response.data.boards.map(board => {
        return board.columns.map(column => {
            const settings = JSON.parse(column.settings_str);
            return settings.boardIds;
        });
        }).flat();

        console.log('boardIds', boardIds); // This will output an array of boardIds
        if(boardIds.length > 0){
            let subitem_board = {"key" : boardIds[0]}
            console.log('selected subitem board', subitem_board)
            setSelectedSubitemProject(subitem_board)
        }
    }

    useEffect(() => {
        if (!_.isEmpty(selectedProject)) {
            fetchUser();
            fetchSubitemBoard();
        }
    }, [selectedProject]);

    const modalTitle = (
        <div style={{ textAlign: "center", marginBottom: 0, paddingBottom: 0 }}>
            <Title
            style={{
                fontWeight: "bold",
                textAlign: "center",
                fontSize: "14px",
                marginBottom: "0px",
                paddingBottom: 0,
            }}
            >
            {title}
            </Title>
            <Paragraph
            style={{
                textAlign: "center",
                fontSize: "12px",
                marginTop: "5px",
                marginBottom: "-10px",
                marginRight: "20px",
                color: "gray",
            }}
            >
            {description.split('\n').map((line, index) => (
                <span key={index}>
                    {line}
                    <br />
                </span>
             ))}
            </Paragraph>
        </div>
        );

    return (
        <>
            <Modal
                title={modalTitle}
                width={500}
                destroyOnClose={true}
                open={show}
                onCancel={() => { setShow(false) }}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
                footer={null}
            >
                <React.Fragment>
                    <Formik
                        validationSchema={formOne ? validateSchema1 :
                            formTwo ? validateSchema2 :
                            formStatus ? emptySchema :validateSchema3}
                        validate={(values) => {
                            const errors = {};
                            console.log("current values",values)
                            if (formThree) {
                                if (!values.teamMember  || (Array.isArray(values.teamMember) && values.teamMember.length === 0)) {
                                    errors.teamMember = "Please select atleast one team member";
                                }
                                else if(values.teamMember){
                                    const activeMembers = values.teamMember.filter((member) => !member.isDeleted) || [];
                                    if(activeMembers.length === 0){
                                        errors.teamMember = "Please select atleast one team member";
                                    }
                                }
                                return errors;
                            }
                        }}
                        validateOnChange={false}
                        style={{ marginRight: '0px' }}
                        initialValues={{
                            workSpaceName: _.get(teamInfo, 'workSpace.key') || null,
                            projectName: _.get(teamInfo, 'project.key') || null,
                            projectBoardId: _.get(teamInfo, 'project.key') || 0,
                            teamName: _.get(teamInfo, 'teamName') || null,
                            pmName: _.get(teamInfo, 'projectManager.userName') || null,
                            pmId: _.get(teamInfo, 'projectManager.userId') || 0,
                            leadName: _.get(teamInfo, 'lead.userName') || null,
                            leadId: _.get(teamInfo, 'lead.userId') || 0,
                            assigneeColName: _.get(teamInfo, 'assigneeColumn.key') || null,
                            assigneeId: _.get(teamInfo, 'assigneeColumn.id') || 0,
                            hoursSpentColName: _.get(teamInfo, 'hoursSpentColumn.key') || null,
                            hoursSpentId: _.get(teamInfo, 'hoursSpentColumn.id') || 0,
                            hoursEstimatedColName: _.get(teamInfo, 'hoursEstimatedColumn.key') || null,
                            hoursEstimatedId: _.get(teamInfo, 'hoursEstimatedColumn.id') || 0,
                            subitemEnabled: _.get(teamInfo, 'subitemEnabled') || false,
                            subitemAssigneeColName: _.get(teamInfo, 'subitemAssigneeColumn.key') || null,
                            subitemAssigneeId: _.get(teamInfo, 'subitemAssigneeColumn.id') || 0,
                            subitemHoursSpentColName: _.get(teamInfo, 'subitemHoursSpentColumn.key') || null,
                            subitemHoursSpentId: _.get(teamInfo, 'subitemHoursSpentColumn.id') || 0,
                            subitemHoursEstimatedColName: _.get(teamInfo, 'subitemHoursEstimatedColumn.key') || null,
                            subitemHoursEstimatedId: _.get(teamInfo, 'subitemHoursEstimatedColumn.id') || 0,
                            teamMates: _.get(teamInfo, 'teamMates') || [],
                            statusColumnName: _.get(teamInfo, 'taskStatusColumn.key') || null,
                            statusColumnId: _.get(teamInfo, 'taskStatusColumn.value') || 0,
                            statusColumnLabelName: _.get(teamInfo, 'taskStatusLabel.key') || null,
                            
                            teamMember: teamMember || []
                        }} onSubmit={handleSubmit}>
                        {(formikBag) => {
                            const { values, setFieldValue, errors } = formikBag;
                            values.teamMates = teamMember || [];
                            let isTeamMateError = (!values.teamMember && (!teamMember?.filter((e) => e.isDeleted === false) || teamMember?.filter((e) => e.isDeleted === false).length === 0))
                            return (
                                <>
                                    {contextHolder}
                                    {formOne && (
                                        <Row style={{ marginBottom: 10 }}>
                                        <Col span={11} >
                                        <div id="workspaceName">
                                                <span style={styles.labelFont}>Workspace</span>
                                                <span style={styles.errorColor}>*</span>

                                                <Field
                                                    name="workSpaceName"
                                                    component={Selectworkspace}
                                                    placeholder="Select Workspace"
                                                    value={values.workSpaceName}
                                                    disabled={id ? true : false}
                                                    cb={(newVal) => {
                                                        
                                                        if (!id && selectedWorkSpace !== newVal[0] && !isPrev) {
                                                             setFieldValue('projectName', null) 
                                                            }
                                                        setFieldValue('workSpaceName', newVal[0].value);
                                                        setSelectedWorkSpace(newVal[0]);
                                                        setIsPrev(false)
                                                    }}
                                                    error={(!values.workSpaceName) ? _.get(errors, 'workSpaceName') : null}
                                                />
                                            </div>
                                            </Col>
                                            <Col span={1}></Col>
                                            <Col span={11}>
                                            <div id="projectName">
                                                <span style={styles.labelFont}>Project</span>
                                                <span style={styles.errorColor}>*</span>

                                            <Field
                                                name="projectName"
                                                component={Selectboard}
                                                placeholder="Select Project"
                                                disabled={id ? true : false}
                                                value={values.projectName}
                                                selectedWorkSpace={selectedWorkSpace}
                                                cb={(newVal) => {
                                                    setFieldValue('projectName', newVal[0]?.value);
                                                    setFieldValue('projectBoardId', newVal[0]?.key);
                                                    setSelectedProject(newVal[0]);
                                                }}
                                                error={(!values.projectName) ? _.get(errors, 'projectName') : null}
                                            />
                                        </div>
                                        </Col>
                                    </Row>
                                    )}
                                    
                                    {formTwo && (
                                        <div>
                                        <Row style={{ marginBottom: 10 }}>
                                            <Col span={11}>
                                            <div id="teamName">
                                                <span style={styles.labelFont}>Team Name</span>
                                                <span style={styles.errorColor}>*</span>

                                                <Field
                                                    name="teamName"
                                                    component={InputComponent}
                                                    placeholder="Team Name"
                                                    value={values.teamName}
                                                    error={(!values.teamName) ? _.get(errors, 'teamName') : null}
                                                />
                                            </div>
                                            </Col>
                                            <Col span={1}></Col>
                                            <Col span={11}>
                                            <div id="assigneeColName">
                                                <span style={styles.labelFont}>Assignee Column (People)</span>
                                                <span style={styles.errorColor}>*</span>

                                                <Field
                                                    name="assigneeColName"
                                                    component={SelectAssigneeColumn}
                                                    placeholder="Select Assignee"
                                                    disabled={isSubmitted}
                                                    value={values.assigneeColName}
                                                    data={userData}
                                                    selectedProject={selectedProject}
                                                    cb={(newVal) => {
                                                        setFieldValue('assigneeColName', newVal[0].value);
                                                        setFieldValue('assigneeId', newVal[0].key);
                                                        setSelectedAssigneeColumn(newVal[0])
                                                    }}
                                                    error={(!values.assigneeColName) ? _.get(errors, 'assigneeColName') : null}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginBottom: 10 }}>
                                        <Col span={11}>
                                        <div id="actualHours">
                                        <span style={styles.labelFont}>Hours Spent (Number)</span>
                                                {/* <span style={styles.errorColor}>*</span> */}

                                                <Field
                                                    name="hoursSpentColName"
                                                    component={SelectNumberColumn}
                                                    placeholder="Select actual hours spent column"
                                                    disabled={isSubmitted}
                                                    value={values.hoursSpentColName}
                                                    data={userData}
                                                    selectedProject={selectedProject}
                                                    cb={(newVal) => {
                                                        setFieldValue('hoursSpentColName', newVal[0] ? newVal[0].value: null);
                                                        setFieldValue('hoursSpentId', newVal[0] ? newVal[0].key: null);
                                                        setSelectedHoursSpentColumn(newVal[0])
                                                    }}
                                                    // error={(!values.hoursSpentColName) ? _.get(errors, 'hoursSpentColName') : null}
                                            />
                                        </div>
                                        </Col>
                                        <Col span={1}></Col>
                                        <Col span={11}>
                                        <div id="estimatedHours">
                                        <span style={styles.labelFont}>Hours Estimated (Number)</span>
                                                {/* <span style={styles.errorColor}>*</span> */}

                                                <Field
                                                    name="hoursEstimatedColName"
                                                    component={SelectNumberColumn}
                                                    placeholder="Select estimated hours spent column"
                                                    disabled={isSubmitted}
                                                    value={values.hoursEstimatedColName}
                                                    data={userData}
                                                    selectedProject={selectedProject}
                                                    cb={(newVal) => {
                                                        setFieldValue('hoursEstimatedColName', newVal[0] ? newVal[0].value: null);
                                                        setFieldValue('hoursEstimatedId',newVal[0] ? newVal[0].key: null);
                                                        setSelectedHoursEstimatedColumn(newVal[0])
                                                    }}
                                                    // error={(!values.hoursSpentColName) ? _.get(errors, 'hoursSpentColName') : null}
                                            />
                                        </div>
                                        </Col>
                                        
                                        </Row>
                                        <Row style={{ marginTop:20, marginBottom: 10 }}>
                                            <Col span={23}>
                                            <div id="subitemSettings" style={{ display: "flex", alignItems: "center" }}>
                                                
                                                <span style={{
                                                    fontWeight: "bold",
                                                    textAlign: "center",
                                                    fontSize: "14px",
                                                    marginRight: "10px" ,
                                                }}>Sub Task Settings</span>

                                                {/* Match below switch UI with subitemSettings text , add some margin */}
                                                <Field name="subitemEnabled" type="checkbox">
                                                    {({ field }) => (
                                                    <Switch size="small"
                                                        {...field}
                                                        value={values.subitemEnabled}
                                                        onChange={(value) => {
                                                            setFieldValue('subitemEnabled', value)
                                                            setSubitemEnabled(value)
                                                        }
                                                            
                                                        }
                                                    />
                                                    )}
                                                </Field>
                                                
                                            </div>
                                            </Col>
                                            
                                        </Row>
                                        {subitemEnabled && (
                                            <div>
                                                <Row style={{ marginBottom: 10 }}>
                                                
                                                    <Col span={11}>
                                                    <div id="subitemAssigneeColName">
                                                        <span style={styles.labelFont}>Assignee Column (People)</span>
                                                        
                                                        <Field
                                                            name="subitemAssigneeColName"
                                                            component={SelectAssigneeColumn}
                                                            placeholder="Select Assignee"
                                                            disabled={isSubmitted}
                                                            value={values.subitemAssigneeColName}
                                                            data={userData}
                                                            selectedProject={selectedSubitemProject}
                                                            cb={(newVal) => {
                                                                setFieldValue('subitemAssigneeColName', newVal[0].value);
                                                                setFieldValue('subitemAssigneeId', newVal[0].key);
                                                                setSelectedSubitemAssigneeColumn(newVal[0])
                                                            }}
                                                            // error={(!values.assigneeColName) ? _.get(errors, 'assigneeColName') : null}
                                                        />
                                                    </div>
                                                    </Col>
                                                </Row>
                                                
                                                <Row style={{ marginBottom: 10 }}>

                                                <Col span={11}>
                                                <div id="subitemActualHours">
                                                <span style={styles.labelFont}>Hours Spent (Number)</span>
                                                        {/* <span style={styles.errorColor}>*</span> */}

                                                        <Field
                                                            name="subitemHoursSpentColName"
                                                            component={SelectNumberColumn}
                                                            placeholder="Select actual hours spent column"
                                                            disabled={isSubmitted}
                                                            value={values.subitemHoursSpentColName}
                                                            data={userData}
                                                            selectedProject={selectedSubitemProject}
                                                            cb={(newVal) => {
                                                                setFieldValue('subitemHoursSpentColName', newVal[0] ? newVal[0].value: null);
                                                                setFieldValue('subitemHoursSpentId', newVal[0] ? newVal[0].key: null);
                                                                setSelectedSubitemHoursSpentColumn(newVal[0])
                                                            }}
                                                            // error={(!values.subitemHoursSpentColName) ? _.get(errors, 'subitemHoursSpentColName') : null}
                                                    />
                                                </div>
                                                </Col>
                                                <Col span={1}></Col>
                                                <Col span={11}>
                                                <div id="subitemEstimatedHours">
                                                <span style={styles.labelFont}>Hours Estimated (Number)</span>
                                                        {/* <span style={styles.errorColor}>*</span> */}

                                                        <Field
                                                            name="subitemHoursEstimatedColName"
                                                            component={SelectNumberColumn}
                                                            placeholder="Select estimated hours spent column"
                                                            disabled={isSubmitted}
                                                            value={values.subitemHoursEstimatedColName}
                                                            data={userData}
                                                            selectedProject={selectedSubitemProject}
                                                            cb={(newVal) => {
                                                                setFieldValue('subitemHoursEstimatedColName', newVal[0] ? newVal[0].value: null);
                                                                setFieldValue('subitemHoursEstimatedId',newVal[0] ? newVal[0].key: null);
                                                                setSelectedSubitemHoursEstimatedColumn(newVal[0])
                                                            }}
                                                            // error={(!values.subitemHoursEstimatedColName) ? _.get(errors, 'subitemHoursEstimatedColName') : null}
                                                    />
                                                </div>
                                                </Col>
                                                
                                                </Row>
                                            </div>
                                        )}
                                    </div>
                                    )}
                                    {formStatus && (
                                        <div>
                                         <Row style={{ marginBottom: 10 }}>
                                            <Col span={11}>
                                            <div id="statusColumnName">
                                                <span style={styles.labelFont}>Select Status Column</span>

                                                <Field
                                                    name="statusColumnName"
                                                    component={SelectStatusColumn}
                                                    placeholder="Select Status"
                                                    disabled={isSubmitted}
                                                    value={values.statusColumnName}
                                                    setFieldValue={setFieldValue}
                                                    data={userData}
                                                    selectedProject={selectedProject}
                                                    cb={(newVal) => {
                                                        const value = _.get(newVal, '[0].value', null);
                                                        const key = _.get(newVal, '[0].key', 0);
                                                        setFieldValue('statusColumnName', value);
                                                        setFieldValue('statusColumnId', key);
                                                        setSelectedStatusColumn(newVal[0]);
                                                    }}
                                                    error={(!values.statusColumnName) ? _.get(errors, 'statusColumnName') : null}
                                                />
                                            </div>
                                            </Col>
                                            <Col span={1}></Col>
                                            <Col span={11}>
                                            <div id="statusColumnLabelName">
                                                <span style={styles.labelFont}>Select Status Label</span>

                                                <Field
                                                    name="statusColumnLabelName"
                                                    component={SelectDoneStatusLabel}
                                                    placeholder="Select Status Label"
                                                    disabled={isSubmitted}
                                                    value={values.statusColumnLabelName}
                                                    statusId={values.statusColumnId}
                                                    data={userData}
                                                    setFieldValue={setFieldValue}
                                                    selectedProject={selectedProject}
                                                    cb={(newVal) => {
                                                        const value = _.get(newVal, '[0].value', null);
                                                        setFieldValue('statusColumnLabelName',value);
                                                        setSelectedStatusLabelColumn(newVal[0]);
                                                    }}
                                                    error={(!values.statusColumnLabelName) ? _.get(errors, 'statusColumnLabelName') : null}
                                                />
                                            </div>
                                            </Col>
                                        </Row> 
                                        </div>
                                    )}
                                    {formThree && (
                                        <div>
                                            <Row style={{ marginBottom: 20 }}>
                                            <Col span={23}>
                                            <div id="pmName">
                                                <span style={styles.labelFont}>Project Manager</span>
                                                <span style={styles.errorColor}>*</span>

                                            <Field
                                                name="pmName"
                                                component={SelectComponent}
                                                placeholder="Select Project Manager"
                                                disabled={isSubmitted}
                                                value={values.pmName}
                                                data={userData}
                                                cb={(newVal) => {
                                                    setFieldValue('pmName', newVal[0]?.value);
                                                    setFieldValue('pmId', newVal[0]?.key);
                                                }}
                                                error={(!values.pmName) ? _.get(errors, 'pmName') : null}
                                            />
                                        </div>
                                        </Col>
                                    </Row>

                                    <Row style={{ marginBottom: 10 }}>
                                    <Col span={23}>
                                    <div id="leadName">
                                            <span style={styles.labelFont}>Team Lead</span>
                                            <span style={styles.errorColor}>*</span>

                                                    <Field
                                                        name="leadName"
                                                        component={SelectComponent}
                                                        placeholder="Select Team Lead"
                                                        value={values.leadName}
                                                        data={userData}
                                                        cb={(newVal) => {
                                                            setFieldValue('leadName', newVal[0]?.value);
                                                            setFieldValue('leadId', newVal[0]?.key);
                                                        }}
                                                        error={(!values.leadName) ? _.get(errors, 'leadName') : null}
                                                    />
                                                </div>
                                                </Col>
                                            </Row>

                                            <Row style={{ marginBottom: 20 }}>
                                                <Col span={23}>
                                                <div id="teamMember">
                                                    <span style={styles.labelFont}>Team Members (Select Multiple Members from list) </span>
                                                    <span style={styles.errorColor}>*</span>

                                                    <Field
                                                        name="teamMember"
                                                        component={SelectComponent}
                                                        placeholder="Select Team Members"
                                                        value={values.teamMember}
                                                        data={userData.filter((item) => item?.key != values.pmId)}
                                                        teamMember={teamMember}
                                                        cb={(newVal) => {
                                                            setFieldValue('teamMember', [...newVal]);
                                                            setNewMembers([...newVal])
                                                            handleAddTeamMember()
                                                        }}
                                                        mode="multiple"
                                                        teamMembers={teamMember}
                                                        handleDeleteTeamMember={handleDeleteTeamMember}
                                                        // error={isTeamMateError ? _.get(errors, 'teamMates') : null}
                                                        error={errors.teamMember}
                                                    />
                                                </div>
                                                </Col>
                                                
                                            </Row>
                                        </div>

                                    )}
                                    
                                    <Row >
                                    <Col span={23} style={{ textAlign: 'center', marginTop: '20px' }}>
                                        <Button 
                                            style={formOne ? styles.prev_btn_disabled_style : styles.prev_btn_style} 
                                            disabled={formOne ? true : false}
                                            onClick={() => {onPrevBtnClick()}}
                                            >
                                            Prev
                                        </Button>
                                        <span style={{ margin: '0 10px' }}></span> {/* Add spacing between buttons */}
      
                                        <Button  style={styles.next_btn_style}
                                            id="teamButton"
                                            type="primary"
                                            disabled={isSubmitted}
                                            // className="float-right"
                                            onClick={formikBag.handleSubmit}
                                        >
                                            {formThree ? ( id ? "Update" : "Create") : "Next"} </Button>
                                        </Col> 
                                    </Row>
                                </>
                            );
                        }}
                    </Formik>
                </React.Fragment>
            </Modal >
        </>
    );
}
const styles = {

    largeWidth: {
        width: 350
    },
    smallWidth: {
        width: 180,
    },
    textSmallFont: {
        fontSize: '12px',
        width: 180,
    },
    textLargeFont:
    {
        fontSize: '12px',
        width: 350
    },
    lableFont: {
        fontSize: '12px',
        marginBottom: '5px' 
    },
    errorColor: {
        color: "red"
    },
    avtarStyle: {
        color: '#f56a00',
        backgroundColor: '#fde3cf',
    },
    buttonExport: {
        float: 'left',
        backgroundColor: 'white',

    },
    formContainer: {
        margin: '10px',
        borderWidth: 1,

    },
    cardFont: {
        fontFamily: 'Poppins',
        letterSpacing: "0em",
        textAlign: 'left',
        fontSize: '12px',
    },
    next_btn_style : {
        backgroundColor: "#1B2F78",
        color: "white",
        borderRadius: '3.25px',
        boxShadow: '0px 1.1267390251159668px 8.435643196105957px 0px #BEBEBE38',
        fontFamily: 'Poppins',
        fontSize: '15px',
        marginBottom: '10px',
        marginTop: '10px' 
    },
    prev_btn_style : {
        backgroundColor: "white",
        color: "#1B2F78",
        borderRadius: '3.25px',
        boxShadow: '0px 5.1267390251159668px 8.435643196105957px 0px #BEBEBE38',
        fontFamily: 'Poppins',
        weight: '500',
        fontSize: '15px',
        border: '0.5px solid #99A5D3', // Add border style here
    },
    prev_btn_disabled_style : {
        backgroundColor: "white",
        color: "#909090",
        borderRadius: '3.25px',
        boxShadow: '0px 5.1267390251159668px 8.435643196105957px 0px #BEBEBE38',
        fontFamily: 'Poppins',
        weight: '500',
        fontSize: '15px',
        border: '0.5px solid #99A5D3', // Add border style here
    },


}
export default TeamInfo;