import React, { useEffect, useState } from "react";
import { Navigate, redirect, useNavigate } from 'react-router-dom';
import MondayConfig from "../../configs/MondayConfig";
import authFunctions from "../../firebaseService/authFunctions";
import firebase from "firebase/compat/app";
import { Alert, Space, Spin } from 'antd';
import moment from "moment";
import { getAuth, setPersistence, onAuthStateChanged, browserSessionPersistence } from "firebase/auth";
// import { auth } from '../firebase';
import sentryUtils from "../../utils/sentryUtils";
import firestore from "../../firebase";
import subscriptionStatus from "../../constants/subscription"
import toastHandler from "../../firebaseService/toastHandler";

import mondaySdk from "monday-sdk-js";
import * as Sentry from "@sentry/react";
import { collection, query, where, and, onSnapshot,getDocs } from "firebase/firestore";
import envUtils from "../../utils/envUtils";

const Auth = () => {
  console.log('Authentication in process')
  let navigate = useNavigate();
  const monday = mondaySdk();
  const [isSubscriptionExpired,setIsSubscriptionExpired] = useState(false)
  const [isTrialEnded,setIsTrialEnded] = useState(false)

  monday.setApiVersion(MondayConfig.MONDAY_API_VERSION);
  
  const getSessionToken = async () => {
    let token = await monday.get("sessionToken");
    // console.log(token.data);

    // query for account & then store the details
    const query = `query {
      me {
          id
          account {
            id
          }
        }
      }`;
    const info = await monday.api(
        query,
        {
          options:{token:token.data},
        }
    ).catch((err) => {
      // Sentry.captureException(err.message)
      console.log(err.message);
      sentryUtils.captureMondayException('getSessionToken', err, 'auth');
    });
    const accId = info?.data?.me?.account?.id.toString();
    const user_id = info?.data?.me?.id.toString();

    if (info) {
      if (token.data != null && token.data !== 'undefined' && accId != null && accId !== 'undefined' && user_id != null && user_id !== 'undefined') {
          localStorage.removeItem("sessionToken_" + accId + "_" + user_id);
          localStorage.setItem("sessionToken_" + accId + "_" + user_id, token.data);
          if (localStorage.getItem("accountId") === accId && localStorage.getItem("userId") === user_id) {
              console.log("user is same");
          } else {
              localStorage.removeItem("accountId");
              localStorage.setItem("accountId", accId);
              localStorage.removeItem("userId");
              localStorage.setItem("userId", user_id);
          }
          if (localStorage.getItem("firestoreToken_" + accId + "_" + user_id)) {
              console.log("Got the firestore token")
              const logIn = await userLoginwithToken();
          } else if (token.data) {
              getAccessTokenFromFirestore()
          }
      }
      localStorage.setItem('is_view_only', 'false')
  } else {
      localStorage.removeItem('is_view_only')
      localStorage.setItem('is_view_only', 'true')
      navigate('/viewer-only')
      return true
  }
  };

  useEffect(() => {
    getSessionToken();
  }, []);

  const getMyInfo = async() =>{
    const query = `query {
      me {
          account {
            slug
          }
         }
      }`;
    const tokendata = await monday.get('sessionToken');
    const info = await monday.api(
        query,
        {
          options:{token:tokendata.data},
        }
    ).catch((err) => {
      // Sentry.captureException(err.message)
      console.log(err.message);
      sentryUtils.captureMondayException('getMyInfo', err, 'auth');
    });
    // console.log(info);
    return info;
  }

  const redirectToOauth = async() => {
    const info =  await getMyInfo();
    // console.log(info);
    const slug = info.data.me.account.slug;
    // console.log(slug);
    let redirect_uri = "http://localhost:8301/oauth/callback";
    if(envUtils.getCurrentMondayApp() === "Demo"){
      redirect_uri = "https://timesheet-398213.web.app/oauth/callback";
    }
    if(envUtils.getCurrentMondayApp() === "MondayProd"){
      redirect_uri = "https://time.apprely.com/oauth/callback"
    }
    window.location.replace(
      `https://auth.monday.com/oauth2/authorize?client_id=${MondayConfig.CLIENT_ID}&response_type=code&subdomain=${slug}&redirect_uri=${redirect_uri}`
    );
  };

const getSubscriptionStatus = async () => {
  const accountId = localStorage.getItem("accountId");
  if (accountId) {
    try {
      const accRef = collection(firestore, "mondayAccounts");
      const accountQuery = query(
        accRef,
        and(
          where("active", "==", true),
          where("accountId", "==", accountId)
        )
      );

      const accountSnapshot = await getDocs(accountQuery);
      let accountEntries = [];
      accountSnapshot.forEach((doc) => {
        const data = doc.data();
        data.id = doc.id;
        accountEntries.push(data);
      });

      if (accountEntries.length > 0) {
        const subscriptionStatusVal = accountEntries[0].subscriptionStatus;
        if (subscriptionStatusVal === subscriptionStatus.SUBSCRIPTION_EXPIRED) {
          return { isSubscriptionExpired: true };
        } else if (subscriptionStatusVal === subscriptionStatus.TRIAL_ENDED) {
          return { isTrialEnded: true };
        }
      }
      return { isSubscriptionExpired: false, isTrialEnded: false };
    } catch (error) {
      // console.log("Error while fetching user entries", error);
      sentryUtils.captureGenericException('fetchAccount', error, 'auth');
      return { isSubscriptionExpired: false, isTrialEnded: false };
    }
  }
  return { isSubscriptionExpired: false, isTrialEnded: false };
};

  const getAccessTokenFromFirestore = async () => {
    const accId = localStorage.getItem("accountId");
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("sessionToken_"+ accId + "_" + userId);
    // console.log('sessionToken', token);
    if (token && token != 'undefined') {
      console.log("token available");
      authFunctions.getAccessTokenFromFirestore().then((result) => {
        const accessToken = result.data;
        // console.log(accessToken);
        if (accessToken === "unauthorized") {
          redirectToOauth();
          console.log("redirecting to oauth");
        } else if (accessToken === "monday session expired") {
          console.log("token expired");
          getSessionToken();
        } else if (accessToken === "authorized") {
          localStorage.removeItem("authenticated_"+ accId + "_" + userId);
          localStorage.setItem("authenticated_"+ accId + "_" + userId, true);
          authFunctions.getFirestoreTokenFromSessionToken().then((result) => {
            // set state as logged in in local storage, which to be used for protected routes
            if (result.data === "monday session expired") {
              console.log("token expired");
              getSessionToken();
            } else {
              const userId = localStorage.getItem("userId");
              localStorage.removeItem("firestoreToken_"+ accId + "_" + userId);
              localStorage.setItem("firestoreToken_"+ accId + "_" + userId , result.data);
              userLoginwithToken();
            }
            
          });
        } else {
          // console.log("accessToken", accessToken);
        }
      });

    } else {
      console.log("sessionToken not available");
      getSessionToken()
    }
  };

  const userLoginwithToken = async () => {
    const accId = localStorage.getItem("accountId");
    const userId = localStorage.getItem("userId");
    const firestoreToken = localStorage.getItem("firestoreToken_" + accId + "_" + userId);
    
    console.log('In check firestoreToken', firestoreToken);
    
    try {
      // Log in with the custom token
      const loggedInCurrentUser = await firebase.auth().signInWithCustomToken(firestoreToken);
      // console.log("Current User:", loggedInCurrentUser);
      const { isSubscriptionExpired, isTrialEnded } = await getSubscriptionStatus();

      if (isSubscriptionExpired) {
        console.log("In subscription auth");
        navigate(`/SubscriptionExpired/${subscriptionStatus.SUBSCRIPTION_EXPIRED}`);
      } else if (isTrialEnded) {
        navigate(`/TrialEnded/${subscriptionStatus.TRIAL_ENDED}`);
      } else {
        navigate('/master/bulktimesheetentry');
      }
  
    } catch (error) {
      console.error("Authentication error:", error);
      const errorCode = error.code;
  
      if (errorCode === "auth/invalid-custom-token") {
        // Handle token refresh and re-login
        localStorage.removeItem("authenticated_" + accId + "_" + userId);
        getSessionToken();
        
        try {
          const result = await authFunctions.getFirestoreTokenFromSessionToken();
          
          if (result.data === "unauthorized") {
            localStorage.removeItem("firestoreToken_" + accId);
            localStorage.removeItem("authenticated_" + accId + "_" + userId);
            redirectToOauth();
            console.log("Redirecting to OAuth");
          } else if (result.data === "monday session expired") {
            console.log("Token expired");
            getSessionToken();
          } else {
            localStorage.setItem("firestoreToken_" + accId + "_" + userId, result.data);
            localStorage.setItem("authenticated_" + accId, true);
            // Retry login after refreshing token
            await userLoginwithToken();
          }
        } catch (tokenError) {
          console.error("Token refresh error:", tokenError);
          sentryUtils.captureFirestoreException('userLoginwithToken', tokenError, 'auth');
        }
      } else {
        console.error("Authentication error:", error);
        sentryUtils.captureFirestoreException('userLoginwithToken', error, 'auth');
      }
    }
  
    const auth = getAuth();
    setPersistence(auth, browserSessionPersistence)
      .then(() => {
        console.log("Session persistence set");
      })
      .catch((error) => {
        console.error("Error setting persistence:", error);
        sentryUtils.captureGenericException('setPersistence', error, 'auth');
      });
  };

  const setAccountId = () => {
    console.log('getting account id...')
    firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        // console.log(idTokenResult.claims.account_id);
        const acId = idTokenResult.claims.account_id;
        localStorage.removeItem("accountId_" + acId);
        localStorage.setItem("accountId_" + acId, acId );

        localStorage.removeItem("authenticated_" + acId);
        localStorage.setItem("authenticated_" + acId, true);
        // navigate('/master/bulktimesheetentry')
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    const accId = localStorage.getItem("accountId");
    const userId = localStorage.getItem("userId");
    // console.log("calling auth.js", typeof(localStorage.getItem('authenticated')));
    if (localStorage.getItem('authenticated_'+ accId + "_" + userId) === 'true') {
      console.log("authenticated is true")
      try{
        onAuthStateChanged(getAuth(), (user) => {
          // console.log(user);
          if (user != undefined && user != null){
            console.log("user is present");
            const expirationTime = user.auth.currentUser.stsTokenManager.expirationTime
            // console.log(expirationTime);
            const currentTime = moment().format('x');
            // console.log(currentTime);
            if(currentTime > expirationTime){
              console.log("login expired");
              userLoginwithToken();
            } else {
              console.log("login not expired");
              // navigate('/master/bulktimesheetentry');
            }
          } else {
            console.log("user is null or undefined");
            userLoginwithToken();
          }
        }); 
      } catch (error) {
        console.log(error);
        userLoginwithToken();
    };

    } else {
      console.log("access token or authenticated flag is not present");
      getAccessTokenFromFirestore();
    }
  }, [localStorage.getItem('authenticated_'+ localStorage.getItem("accountId")+ "_" + localStorage.getItem("userId"))]);


  return (
    <div class="container" style={{overflow:"hidden",height:"75%",width:"100%"}}>
      <div class="container-fluid" >
        <div class="d-flex flex-column min-vh-100 justify-content-center align-items-center">
          <Space direction="vertical" style={{ width: '100%' }}>
            <Spin tip="Authenticating..." size="large">
              <div className="content" />
            </Spin>
          </Space>
        </div>
      </div>
    </div>
  );
};

export default Auth;
